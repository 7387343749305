import React, { Component, Fragment } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { DAEMON } from 'utils/constants'
import reducer from './reducers'
import saga from './sagas'

import { push } from 'connected-react-router'
import { getNamespaceContents, getNamespaceInfrastructure } from './actions'
import {
  selectNamespaceContents,
  selectNamespaceInfrastructure,
} from './selectors'

import NodeViewHeader from 'containers/NodeViewHeader'
import NodeView from 'containers/NodeSolutionCenter/NodeView'
import K8sInfrastructure from 'components/NodeView/k8s/K8sInfrastructure'
import K8sNamespaceContents from './K8sNamespaceContents'

class K8sNamespaceNodeViewCardLayout extends Component {
  componentDidMount() {
    this.props.getNamespaceContents({ srn: this.props.nodeId })
    this.props.getNamespaceInfrastructure({ srn: this.props.nodeId })
  }

  getGridContent = () => {
    return [
      <div key="namespaces">
        <K8sNamespaceContents
          namespaceContents={this.props.namespaceContents}
          push={this.props.push}
        />
      </div>,
    ]
  }

  getLayouts = () => {
    const layouts = {
      namespaces: {
        lg: { w: 2, h: 3.5 },
        xs: { w: 2, h: 3.5 },
      },
    }

    return layouts
  }

  getCols = () => {
    return {
      lg: 2,
      xs: 2,
    }
  }

  getTabs = () => {
    const InfrastructureContainer = styled.div`
      height: 650px;
    `
    return [
      <div label="Infrastructure" key="infrastructure">
        <InfrastructureContainer>
          <K8sInfrastructure
            clusterInfrastructure={this.props.namespaceInfrastructure}
            push={this.props.push}
          />
        </InfrastructureContainer>
      </div>,
    ]
  }

  render() {
    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          onNodeView
          horizontal={this.props.horizontal}
        />
        <div className="k8s-namespace-nodeviewcardlayout">
          <NodeView
            {...this.props}
            horizontal={this.props.horizontal}
            renderDetailsTab
            layouts={this.getLayouts()}
            cols={this.getCols()}
            gridContent={this.getGridContent()}
            tabs={this.getTabs()}
          />
        </div>
      </Fragment>
    )
  }
}

K8sNamespaceNodeViewCardLayout.propTypes = {
  horizontal: PropTypes.bool,
  nodeId: PropTypes.string.isRequired,

  // ~~ bound action creators --
  push: PropTypes.func.isRequired,
  getNamespaceContents: PropTypes.func.isRequired,
  getNamespaceInfrastructure: PropTypes.func.isRequired,

  // ~~ props from redux ~~
  namespaceContents: ImmutablePropTypes.map,
  namespaceInfrastructure: ImmutablePropTypes.map,
}

const mapStateToProps = createStructuredSelector({
  namespaceContents: selectNamespaceContents,
  namespaceInfrastructure: selectNamespaceInfrastructure,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
      getNamespaceContents,
      getNamespaceInfrastructure,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

const KEY = 'k8sNamespaceNodeView'
const withReducer = injectReducer({ key: KEY, reducer })
const withSaga = injectSaga({
  key: KEY,
  saga: saga,
  DAEMON,
})

export default compose(
  withConnect,
  withReducer,
  withSaga
)(K8sNamespaceNodeViewCardLayout)
