import { createAction } from 'redux-actions'
import {
  GET_NAMESPACE_CONTENTS,
  GET_NAMESPACE_CONTENTS_SUCCESS,
  GET_NAMESPACE_CONTENTS_ERROR,
  GET_NAMESPACE_INFRASTRUCTURE,
  GET_NAMESPACE_INFRASTRUCTURE_SUCCESS,
  GET_NAMESPACE_INFRASTRUCTURE_ERROR,
} from './constants'

export const getNamespaceContents = createAction(GET_NAMESPACE_CONTENTS)
export const getNamespaceContentsSuccess = createAction(
  GET_NAMESPACE_CONTENTS_SUCCESS
)
export const getNamespaceContentsError = createAction(
  GET_NAMESPACE_CONTENTS_ERROR
)

export const getNamespaceInfrastructure = createAction(
  GET_NAMESPACE_INFRASTRUCTURE
)
export const getNamespaceInfrastructureSuccess = createAction(
  GET_NAMESPACE_INFRASTRUCTURE_SUCCESS
)
export const getNamespaceInfrastructureError = createAction(
  GET_NAMESPACE_INFRASTRUCTURE_ERROR
)
