import React, { Component } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import _ from 'lodash'

import BorderedCard from 'components/BorderedCard'
import DataTable from 'components/DataTable'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SectionHeader from 'components/SectionHeader'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import TextLink from 'components/TextLink'

import { getTypeFromSrn } from 'utils/graphDataUtils'
import { getNodeViewPushParams } from 'utils/sonraiUtils'

import messages from './messages'

class K8sNamespaceContents extends Component {
  getNamespaceContentData = () => {
    return this.props.namespaceContents
      .get('data')
      .map(row => {
        return {
          ..._.pick(row.toJS(), ['name', 'label', 'type', 'srn']),
          gotoNodeView: row.get('srn'),
        }
      })
      .toJS()
  }

  renderBody = () => {
    if (this.props.namespaceContents.get('loading')) {
      return <SquareLoadingAnimation />
    }

    if (this.props.namespaceContents.get('error')) {
      return <DynamicFormattedMessage {...messages.errorLoadingData} />
    }

    // table data default sort: primary = label, secondary = name
    let tableData = this.getNamespaceContentData()
    tableData = _.sortBy(tableData, row => row.name)
    tableData = _.sortBy(tableData, row => row.label)

    return (
      <DataTable
        data={tableData}
        customColumnConfig={{
          name: {
            width: 350,
            minWidth: 350,
          },
          srn: {
            width: 500,
            minWidth: 500,
          },
          gotoNodeView: {
            width: 150,
            minWidth: 150,
            pinned: 'right',
            headerName: '',
            suppressMenu: true,
            cellRendererFramework: params => {
              const srn = params.value
              const nodeType = getTypeFromSrn(srn)
              const urlParams = getNodeViewPushParams(srn, nodeType)

              return (
                <TextLink
                  color="primary"
                  onClick={() => {
                    this.props.push(urlParams)
                  }}
                >
                  Go To Details
                </TextLink>
              )
            },
          },
        }}
      />
    )
  }

  render() {
    return (
      <BorderedCard style={{ height: '100%' }}>
        <SectionHeader>Contents</SectionHeader>
        <div style={{ height: 'calc(100% - 40px)' }}>{this.renderBody()}</div>
      </BorderedCard>
    )
  }
}

K8sNamespaceContents.propTypes = {
  namespaceContents: ImmutablePropTypes.contains({
    data: ImmutablePropTypes.list,
    error: PropTypes.error,
    loading: PropTypes.bool,
  }),
  push: PropTypes.func.isRequired,
}

export default K8sNamespaceContents
