import { defineMessages } from 'react-intl'

export default defineMessages({
  errorLoadingData: {
    id:
      'app.containers.NodeViewLayouts.K8sNamespaceNodeViewCardLayout.errorLoadingData',
    defaultMessage:
      'There was an error fetching the contents of the namespace.',
  },
})
