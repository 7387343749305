import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import {
  GET_NAMESPACE_CONTENTS,
  GET_NAMESPACE_CONTENTS_SUCCESS,
  GET_NAMESPACE_CONTENTS_ERROR,
  GET_NAMESPACE_INFRASTRUCTURE,
  GET_NAMESPACE_INFRASTRUCTURE_ERROR,
  GET_NAMESPACE_INFRASTRUCTURE_SUCCESS,
} from './constants'

const initialState = fromJS({
  namespaceContents: {
    data: null,
    error: false,
    loading: false,
  },
  namespaceInfrastructure: {
    data: null,
    error: false,
    loading: false,
  },
})

const k8sNamespaceNodeViewReducer = handleActions(
  {
    [GET_NAMESPACE_CONTENTS]: state =>
      state
        .setIn(['namespaceContents', 'error'], false)
        .setIn(['namespaceContents', 'loading'], true),
    [GET_NAMESPACE_CONTENTS_ERROR]: state =>
      state
        .setIn(['namespaceContents', 'error'], true)
        .setIn(['namespaceContents', 'loading'], false),
    [GET_NAMESPACE_CONTENTS_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['namespaceContents', 'error'], false)
        .setIn(['namespaceContents', 'loading'], false)
        .setIn(['namespaceContents', 'data'], fromJS(payload.data)),

    [GET_NAMESPACE_INFRASTRUCTURE]: state =>
      state
        .setIn(['namespaceInfrastructure', 'error'], false)
        .setIn(['namespaceInfrastructure', 'loading'], true),
    [GET_NAMESPACE_INFRASTRUCTURE_ERROR]: state =>
      state
        .setIn(['namespaceInfrastructure', 'error'], true)
        .setIn(['namespaceInfrastructure', 'loading'], false),
    [GET_NAMESPACE_INFRASTRUCTURE_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['namespaceInfrastructure', 'error'], false)
        .setIn(['namespaceInfrastructure', 'loading'], false)
        .setIn(['namespaceInfrastructure', 'data'], fromJS(payload.data)),
  },
  initialState
)

export default k8sNamespaceNodeViewReducer
