import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectK8sNamespaceNodeView = state =>
  state.get('k8sNamespaceNodeView') || Map()

export const selectNamespaceContents = createSelector(
  selectK8sNamespaceNodeView,
  state => state.get('namespaceContents')
)

export const selectNamespaceInfrastructure = createSelector(
  selectK8sNamespaceNodeView,
  state => state.get('namespaceInfrastructure')
)
