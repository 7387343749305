import { all, put, takeLatest } from 'redux-saga/effects'
import gql from 'graphql-tag'
import _ from 'lodash'
import { getClient } from 'apolloClient'

import {
  getNamespaceContentsError,
  getNamespaceContentsSuccess,
  getNamespaceInfrastructureError,
  getNamespaceInfrastructureSuccess,
} from './actions'
import {
  GET_NAMESPACE_CONTENTS,
  GET_NAMESPACE_INFRASTRUCTURE,
} from './constants'

const GET_NAMESPACE_CONTENTS_QUERY = gql`
  query getClusterContent($srn: String!) {
    ResourceGroups(where: { srn: { value: $srn } }) {
      items {
        srn
        account
        createdDate
        observedDate
        active
        name
        friendlyName
        tagSet
        type
        ...containedWorkloads
      }
    }
  }

  fragment containedWorkloads on Resource {
    contains {
      items {
        srn
        name
        friendlyName
        label
        ... on Workload {
          type
        }
        ... on User {
          type
        }
        ... on Networkcomponent {
          type
        }
        ... on Identityreference {
          type
        }
        ... on Secretstore {
          type
        }
      }
    }
  }
`

function* getNamespaceContents(action) {
  try {
    const { srn } = action.payload
    const client = yield getClient()
    const result = yield client.query({
      query: GET_NAMESPACE_CONTENTS_QUERY,
      variables: { srn },
    })

    const path = ['data', 'ResourceGroups', 'items', 0, 'contains', 'items']
    const data = _.get(result, path, null)
    if (data === null) {
      throw 'the result did not contain data at ' + path.join('/')
    }

    yield put(getNamespaceContentsSuccess({ data }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error happened fetching k8s namespace contents: ', e)
    yield put(getNamespaceContentsError())
  }
}

const GET_CLUSTER_INFRA_QUERY = gql`
  query getK8sClusterContentsForNamespace($srn: String) {
    ResourceGroups(
      where: { srn: { value: $srn }, type: { value: K8SNamespace } }
    ) {
      items {
        srn
        name
        isIn(
          where: {
            label: { value: "Account" }
            on__Account: { type: { value: K8SCluster } }
          }
        ) {
          items {
            name
            isIn(
              where: {
                label: { value: "Compute" }
                on__Compute: { type: { value: AzureKubernetesCluster } }
              }
            ) {
              items {
                contains {
                  items {
                    srn
                    label
                    active
                    createdDate
                    ... on Resource {
                      name
                    }
                    ... on Network {
                      type
                    }
                    ... on Networksubnet {
                      type
                    }
                    ... on Networkpolicy {
                      type
                    }
                    ... on Blockstorage {
                      type
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

function* getNamespaceInfrastructure(action) {
  try {
    const { srn } = action.payload
    const client = yield getClient()
    const result = yield client.query({
      variables: { srn },
      query: GET_CLUSTER_INFRA_QUERY,
    })
    const path = [
      'data',
      'ResourceGroups',
      'items',
      0,
      'isIn',
      'items',
      0,
      'isIn',
      'items',
      0,
      'contains',
      'items',
    ]
    const data = _.get(result, path, null)
    if (data === null) {
      throw 'no data at path ' + path.join('/')
    }
    yield put(getNamespaceInfrastructureSuccess({ data }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error happened fetching k8s ns infrastructure: ', e)
    yield put(getNamespaceInfrastructureError())
  }
}

function* k8sNamespaceNodeViewSaga() {
  yield all([
    takeLatest(GET_NAMESPACE_CONTENTS, getNamespaceContents),
    takeLatest(GET_NAMESPACE_INFRASTRUCTURE, getNamespaceInfrastructure),
  ])
}

export default k8sNamespaceNodeViewSaga
